import {Tooltip} from 'antd';

import createRoutes from '../../../routerUtils';
import MultiTabLink from 'components/common/multiTabLink';
import SidebarIcons from './sidebarIcons';

const renameUri = uri => uri?.split('-')?.join('_');

// Creating Tooltips for length > 20
function createTooltip(child, parent) {
  return {
    ...child,
    key: child.key,
    icon: SidebarIcons[renameUri(child.uri)]
      ? SidebarIcons[renameUri(child.uri)]
      : parent[0] === 'Hisaab'
        ? SidebarIcons.icon3
        : SidebarIcons.icon2,
    label: (
      <Tooltip title={child.name}>
        <div>
          <MultiTabLink className="font-semibold" title={child.name} url={`/#${child.key}`} />
        </div>
      </Tooltip>
    ),
  };
}

// Creating Sidebar menu w.r.t selected dashboard
function createSidebarMenu(permissions, dashboard) {
  // Collecting Routes
  const Routes = createRoutes(permissions[dashboard]);

  // Mapping Routes to each report w.r.t URI
  const mappedSideBarMenu = permissions[dashboard].map(item => {
    let mappedItem = {};
    Routes.forEach(data => {
      if (data.uri && data.uri === item.uri) {
        mappedItem = {
          ...item,
          key: data.path,
        };
      }
    });
    return mappedItem;
  });

  // Getting Unique Parent Header
  const uniqueParentHeader = [...new Set(mappedSideBarMenu.map(item => item.parent_header || ''))];

  // Pairing children to each parent
  const filteredParentChild = uniqueParentHeader.map(item => {
    let children = {};
    // reports with parent_headers = null are named as 'no child'
    const parentHeader = item === '' ? 'no child' : item;
    children = {
      [parentHeader]: mappedSideBarMenu.filter(data => data.parent_header === (item === '' ? null : item)),
    };
    return children;
  });

  // Adding label, key, icons to each report
  const sideBarMenuTemp = [];
  filteredParentChild.forEach(item => {
    const parent = Object.keys(item);
    const children = Object.values(item);
    if (parent[0] !== 'no child') {
      // Reports with children
      sideBarMenuTemp.push({
        label: <p className="font-semibold m-0">{parent[0]}</p>,
        key: parent[0].split(' ').join('-'),
        parent_position: children[0][0].parent_position,
        icon: SidebarIcons[renameUri(children[0][0].parent_uri)]
          ? SidebarIcons[renameUri(children[0][0].parent_uri)]
          : SidebarIcons.dashboard,
        children: children[0]
          .sort((a, b) => Number(a.child_position) - Number(b.child_position))
          .map(child => {
            if (child.name.length > 20) {
              return createTooltip(child, parent);
            }
            return {
              ...child,
              key: child.key,
              label: <MultiTabLink className="font-semibold" title={child.name} url={`/#${child.key}`} />,
              icon: SidebarIcons[renameUri(child.uri)]
                ? SidebarIcons[renameUri(child.uri)]
                : parent[0] === 'Hisaab'
                  ? SidebarIcons.icon3
                  : SidebarIcons.icon2,
            };
          }),
      });
    } else {
      // Reports with no children
      sideBarMenuTemp.push(
        ...children[0].map(child => {
          if (child.name.length > 20) {
            return createTooltip(child, parent);
          }
          return {
            ...child,
            key: child.key,
            label: <MultiTabLink className="font-semibold" title={child.name} url={`/#${child.key}`} />,
            icon: SidebarIcons[renameUri(child.uri)]
              ? SidebarIcons[renameUri(child.uri)]
              : SidebarIcons[child.name === 'Dashboard' ? 'dashboard' : 'icon2'],
          };
        })
      );
    }
  });

  return sideBarMenuTemp.sort((a, b) => Number(a.parent_position) - Number(b.parent_position));
}

export default createSidebarMenu;
